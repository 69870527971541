import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Layout from '@components/layout';
import SEO from '@components/seo';
import CategorySidebar from '@components/categorySidebar';
import Listing from '@components/listing';
import { SearchContext } from '@components/searchContext';
import Search from '@components/searchWrapper';
import ListingsStyles from '@styles/listings';
import { FixedSearchStyles } from '@styles/search';
import SearchIcon from '@icons/search';

export default function SearchPage({ location }) {
  const initial = decodeURIComponent(location.search.replace(`?q=`, ``));
  return (
    <Layout page="/search">
      <SearchContext.Consumer>
        {({ search, results }) => (
          <>
            <SEO title={search} />
            <FixedSearchStyles>
              <Search page="/search" initial={initial} />
            </FixedSearchStyles>
            <ListingsStyles>
              <CategorySidebar page="/search" location={location} />
              <div className="content-wrapper">
                <div className="listings">
                  {results.length > 0 ? (
                    <>
                      {/* display equipment first */}
                      {results.map(
                        result =>
                          result.type === `equipment` && (
                            <Listing
                              listing={{
                                data: {
                                  ...result,
                                  title: { text: result.title },
                                },
                                uid: result.slug,
                                type: result.type,
                              }}
                              key={result.id}
                            />
                          )
                      )}
                      {/* then display attachments and for sale items */}
                      {results.map(
                        result =>
                          result.type !== `equipment` && (
                            <Listing
                              listing={{
                                data: {
                                  ...result,
                                  title: { text: result.title },
                                },
                                uid: result.slug,
                                type: result.type,
                              }}
                              key={result.id}
                            />
                          )
                      )}
                    </>
                  ) : (
                    <NoSearchWrapper>
                      <SearchIcon />
                      <p>No results to show {search && `for "${search}"`}</p>
                    </NoSearchWrapper>
                  )}
                </div>
              </div>
            </ListingsStyles>
          </>
        )}
      </SearchContext.Consumer>
    </Layout>
  );
}

SearchPage.propTypes = {
  location: PropTypes.object.isRequired,
};

const NoSearchWrapper = styled.div`
  min-height: 50rem;
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 20rem;
    height: 20rem;
  }

  svg path {
    fill: ${props => props.theme.lightgrey};
  }

  p {
    margin-top: 3rem;
    text-align: center;
    font-weight: 500;
  }
`;
